<template>
  <div class="admin-print">
    <div class="admin-bar">
      <div class="nav-btn" @click="goto('/admin')"><i class="icon el-icon-back"></i> 返回</div>
    </div>
    <div class="admin-body">
      <el-card class="box-card" style="width:100%">
        <div class="list-wrap">
          <div class="search-wrap">
            <div class="total">已参观人次: <strong>{{total}}</strong></div>
            <div class="search">
              <el-form :inline="true"  class="demo-form-inline" onsubmit="return false;">
                <el-form-item label="">
                  <el-input v-model="search.keywords"
                    placeholder="请输入关键字"
                    @keyup.enter.native="onSubmit"
                    id="qr-input"
                    size="small"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit" size="small">搜索</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="list">
            <el-table
                :data="list"
                :row-class-name="tableRowClassName"
                >
              <el-table-column
                  type="index"
                  label="序号"
                  width="100"
              >
              </el-table-column>
              <el-table-column
                  prop="mv_name"
                  label="姓名"
                  width="220"
              >
              </el-table-column>

              <el-table-column
                  prop="mv_mobile"
                  label="手机号"
                  width="280">
              </el-table-column>

              <el-table-column
                  prop="no"
                  label="参观编号"
                  width="280"
              >
              </el-table-column>
              <el-table-column
                  prop="orderdate"
                  label="参观日期"
                  width="280"
              >
              </el-table-column>
             

              <el-table-column
                  prop="mv_code"
                  label="健康码状态"
                  width="120">
                <template slot-scope="scope">
                <span class="code_green" v-if="scope.row.mv_code_state == 1">{{ scope.row.mv_code}}</span>
                <span class="code_yello" v-else-if="scope.row.mv_code_state == 2">{{ scope.row.mv_code}}</span>
                <span class="code_red" v-else-if="scope.row.mv_code_state == 3">{{ scope.row.mv_code}}</span>
                <span v-else>{{ scope.row.mv_code}}</span>
              </template>
              </el-table-column>

              <el-table-column
                  fixed="right"
                  label="操作"
                 >
                <template slot-scope="scope">
                  <el-button @click="myHealthy(scope.row,scope.index)" size="small" type="success">核验健康码</el-button>
                  <el-button @click="myPrint(scope.row)" size="small">打印</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>
      </el-card>
    </div>
    <div class="printDiv" id="printDiv" v-if="item">
      <div class="cert-item" style="margin-top: 100px;margin-left: 60px;">
        <p class="item-t">{{item.mv_name}}，您好！欢迎参观上海体育博物馆。</p>
        <p class="item-t">参观日期：{{item.orderdate}}</p>
        <p class="item-t">参观编号：{{item.no}}</p>
      </div>
      <div class="qrcode" v-if="item.device_code">
        <div class="cert-code" style="margin-left: 60px;margin-top: 40px;"><Code :value="item.device_code" :width="60" :height="60" /></div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/api'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import Code from '@/components/code'
import ScanReader from '@/libs/scanner'

export default {
  components:{
    Code
  },
  data() {
    return {
      search: {
        keywords: '',
        date: '',
        jid: '',
        page: 1
      },
      list: [],
      totalPage: 10,
      total: 0,
      item:{},
      scan: null
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    ...mapGetters(["getUser"]),
    tableRowClassName({row, rowIndex}) {
      if (row.datestate == 2) {
        return 'warning-row'
      } else if (row.datestate == 1) {
        return 'success-row'
      } else {
        return ''
      }
    },
    goto(path) {
      this.$router.push({
        path
      })
    },
    load() {
      api.printList( this.search).then(r => {
        if(r.data.statusCode == 200)
        {
          this.list = r.data.data.list
          if(this.list.length == 0)
          {
            this.$message({
              message: '查询无数据',
              type: 'warning'
            });
          }else{
            this.item = this.list[0];
          }
          this.total = r.data.data.total
        }
      }).catch(()=>{
        this.$message({
          message: '网路错误',
          type: 'warning'
        });
      })
    },
    onSubmit() {
      this.load()
    },
    handleCurrentChange(page) {
      this.search.page = page
      this.load()
    },
    myHealthy(row,index)
    {
        api.Healthy({odid:row.id}).then(res => {
            if (res.data.status == 200) 
            {
              if(res.data.data.state == '0000')
              {
                row.mv_code_state = 1;
                row.mv_code = "绿码";
              }else if(res.data.data.state == '0002')
              {
                row.mv_code_state = 2;
                row.mv_code = "黄码";
              }else if(res.data.data.state == '0003')
              {
                row.mv_code_state = 3;
                row.mv_code = "红码";
              }else 
              {
                row.mv_code_state = 3;
                row.mv_code = "未查询到健康码";
              }
                 return '';
            } 
        })
    },
    myPrint(row)
    {
      console.log(row,'row')
      api.printDetail(row.id, 0).then(r => {
        if (r.data.status == 200) {
          return Promise.resolve(r.data.data)
        } else {
          return this.$confirm('当前明信片不可用于入场验证，是否仍然打印？')
            .then(_ => {
              return api.printDetail(row.id, 1).then(res => {
                if (res.data.status == 200) {
                  return Promise.resolve(res.data.data)
                } else {
                  this.$message('强制打印出错，请联系管理员！')
                  return Promise.reject('标记　isforce 错误')
                }
              }).catch(_ => {
                return Promise.reject('标记　isforce 错误')
              })
            })
            .catch(_ => {
              return Promise.reject('已取消')
            })
        }
      }).then(r => {
        this.item = r
        setTimeout(()=>{
          var newWindow=window.open("打印窗口","_blank");//打印窗口要换成页面的url
          let obj = document.getElementById('printDiv');
          var docStr = obj.innerHTML;
          newWindow.document.write(docStr);
          newWindow.document.close();
          setTimeout(() => {
            newWindow.print();
            newWindow.close();
          }, 500)
        }, 200)
      }).catch((e) => {
        console.log(e)
      })
    }
  },
  mounted() {
    this.load()
    let that = this
    let reader = new ScanReader(function(v, o) {
          that.search.keywords = v
          that.load()
      }, document.getElementById('qr-input'))
    reader.init()
    this.scan = reader
  },
}
</script>

<style scoped>
.code_green
{
  color:green;
}
.code_yellow
{
  color:yellow;
}
.code_red
{
  color:red;
}
.admin-print
{
  text-align: initial;
}
.admin-bar {
  background: #C28A52;
  box-shadow: 0 5px 5px #684624;
  height: 3rem;
  display: flex;
  justify-content: stretch;
  padding: 0 2rem;
}

.admin-bar .nav-btn {
  margin-top: 10px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #fff;
  padding: 0 15px 0 10px;
  color: #fff;
  font-size: 14px;
}

.admin-body {
  display: flex;
  flex-direction:row;
  justify-content: stretch;
  padding: 2rem;
}
.admin-body .search {
  background: #fff;
}
.footer {
  margin-top: 1rem;
}

.printDiv
{
  width: 567px;
  height: 378px;
  border: 1px solid red;
  /*position: absolute;*/
  /*top: -9000px;*/
}

.search-wrap {
  display: flex;
}

.search-wrap .total {
  padding: 5px 15px 0 0;
  line-height: 2;
}

</style>

<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
