<template>
<div class="queue-wrapper">
  <div class="queue-wrap">
    <div class="queue-content">
      <div class="queue-head"><div>当前叫号</div></div>
      <div class="queue-body">
        <div class="item">
          <div class="title">
            <span>马路演绎</span>
            <span>Crazy Racing</span>
          </div>
          <div class="num" :class="{small: fontA}">{{item.A}}</div>
        </div>
        <div class="item">
          <div class="title">
            <span>影动时空</span>
            <span>Super Hero</span>
          </div>
          <div class="num" :class="{small: fontB}">{{item.B}}</div>
        </div>
        <div class="item">
          <div class="title">
            <span>雪地英雄</span>
            <span>Skiing Simulator</span>
          </div>
          <div class="num" :class="{small: fontC}">{{item.C}}</div>
        </div>
        <div class="item">
          <div class="title">
            <span>魔幻单车</span>
            <span>Magical Bike</span>
          </div>
          <div class="num" :class="{small: fontD}">{{item.D}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import api from '@/api/api'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  data() {
    return {
      item: {
        A: '',
        B: '',
        C: '',
        D: '',
      }
    }
  },
  computed: {
    fontA() {
      return this.smallFont(this.item['A']) 
    },
    fontB() {
      return this.smallFont(this.item['B']) 
    },
    fontC() {
      return this.smallFont(this.item['C']) 
    },
    fontD() {
      return this.smallFont(this.item['D']) 
    },
  },
  methods: {
    ...mapMutations(["setUser"]),
    ...mapGetters(["getUser"]),
    smallFont(v) {
      return v === '暂无叫号'
    },
    goto(path) {
      this.$router.push({
        path
      })
    },
    load() {
      api.screen().then(r => {
        r.data.data.map(v => {
          if (v.id > 0) {
            this.item[v.stype] = v.no
          } else {
            this.item[v.stype] = '暂无叫号'
          }
        })
      })
    }
  },
  mounted() {
    this.load()
    setInterval(this.load, 5000)
  },
}
</script>

<style scoped>
.queue-wrapper {
  background: #133f6b;
  margin:0;
  padding:0;
  width: 100%;
  height: 100%;
}

.queue-wrap {
  margin: 0 auto;
  width: 1920px;
  height: 1080px;
  position: relative;
  background: #133f6b center -20px url("../assets/bg.png") no-repeat;
}

.queue-content {
  position: absolute;
  width: 1200px;
  height: 250px;
  top: 0;
  right: 0;
  background: #59789b;
  display: flex;
}

.queue-head {
  font-family: '黑体', 'Courier New', Courier, monospace;
  background: #dce3e9;
  width: 70px;
  height: 250px;
  font-size: 36px;
  padding: 0 5px;
  line-height: 1.3;
}

.queue-head div{
  padding-top: 44px;
  color: #133f6b;
}

.queue-body {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  color: #ECC201;
}

.queue-body .title {
  padding-top: 50px;
  font-size: 28px;
}

.queue-body .title span {
  display: block;
}

.queue-body .num {
  font-size: 56px;
  line-height: 2;
}

.queue-body .num.small {
  font-size: 36px;
  line-height: 3;
}
</style>

<style>

body {
  /** #59789b dce3e9 */
  margin:0; 
  padding:0;
}

.queue-list-header th.col{
  font-size: 20px;
  background: #eee;
  color:#684624;
}

.col {
  font-size: 16px;
}
</style>