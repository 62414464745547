
export default class ScanReader {
  constructor(cb, input, thres) {
    this.cb=cb
    this.input=input
    this.threshold=thres||100
    this.lastInputTime=0
    this.chars=[]
    this.enabled=1
  }
  init() {
    let that=this;
    document.onkeydown=function(e) {
      if(!that.enabled) {
        return
      }
      let time=(new Date).getTime()
      let span=time-that.lastInputTime
      if(span>that.threshold) {
        that.chars=e.key.length===1? [e.key]:[]
      } else {
        if(e.key==='Enter') {
          that.cb(that.chars.join(''), that)
        } else {
          if(e.key.length===1)
            that.chars.push(e.key)
        }
      }
      that.lastInputTime=time;
    };
    that.input.onfocus=function() {
      that.enabled=0
      console.log("focused")
    };
    that.input.onblur=function() {
      console.log("blured")
      that.enabled=1
    };
  }
}